.root {
    flex-grow: 1
  }
  .invoice-section{
      margin:0px auto
  }
  .invoice-title{
    margin: 0;
    padding: 0;
    text-align: center;
    border-top: 1px dotted #222;
    border-bottom: 1px dotted #222;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 2px;
  }
  .invoice-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-size:12px;
    color:#222
  }
  .invoice-table td{
    padding: 1px 1px 0px 1px;
    border: 1px solid #222;
    color: #222;
    font-size: 15px !important;
    /* font-weight: bold; */
  }
  .invoice-table thead{
    font-weight: bold
  }
  
  .invoice-table-challan{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-size:14px;
    color:#222
  }


  .tableset{
    
  }

  .tableset td{
    font-size: 15px;
  }

  .invoice-table-challan td{
    padding: 1px 1px 0px 1px;
    border: 1px solid #222;
    color: #222;
    font-size: 14px !important;
  }
  .invoice-table-challan thead{
    font-weight: bold
  }
  

  .invoice-table-challan td{
    padding: 1px 1px 0px 1px;
    border: 1px solid #222;
    color: #222;
    font-size: 14px !important;
  }

  .invoice-head{
   
  }
  .invoice-logo{
    float: left;
    padding-left: 5%;
  }
  .invoice-logo{
  
  }
  .invoice-desc{
    float: left;
    padding-left: 5px;
  }
  .invoice-desc h2{
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .invoice-desc p{
     font-size: 16px;
     font-family: Arial, Helvetica, sans-serif;
  }
  
  .invoice-footer {
    width: 100%;
    overflow: hidden;
  }
  
  .invoice-footer-bottom{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 125px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 6px;
    /* overflow: hidden; */
  }
  
  body {
    
  }
  .print-source {
    display: none;
  }
  
  body > .print-source {
    display: block !important
  }
  
  @media print {
    .print-source {
      display: block;
      /* background-color: green; */
    }
    .justshow{
      display: none !important;
    }

    .invoice-table td{
      border:none !important;
    }
  }
  